<template>
  <HomeView />
</template>
<script>
export default {
  components: {
    HomeView: require('./views/HomeView.vue').default
  }
}
</script>
